.swiper {
  z-index: 0;
  position: relative;
  box-shadow: 0 0 10px #0000008b;
}

.swiper-slide {
  width: 50vw;
  text-align: center;
  background: #fff;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  display: flex;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 50vw;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.mySwiper2 {
  height: 65vh;
  width: 50vw;
  border: 1px solid #ffffff80;
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}

.mySwiper {
  height: 20vh;
  width: 50vw;
  border: 1px solid #ffffff80;
  border-top: none;
  border-radius: 0 0 10px 10px;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 100%;
  height: 100%;
  opacity: .4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
}

@media (max-width: 930px) {
  .mySwiper2 {
    height: 45vh;
    width: 95vw;
  }

  .mySwiper {
    height: 15vh;
    width: 95vw;
  }
}

/*# sourceMappingURL=index.c2363976.css.map */
