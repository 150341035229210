      .swiper {
        position: relative;
        z-index: 0;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.546);
      }

      .swiper-slide {
         width: 50vw; 
        text-align: center;
        font-size: 18px;
        background: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
      }

      .swiper-slide img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .swiper {
        width: 100%;
        height: 50vw;
        margin-left: auto;
        margin-right: auto;
      }

      .swiper-slide {
        background-size: cover;
        background-position: center;
      }

      .mySwiper2 {
        height: 65vh;
        width: 50vw;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-bottom: none;
        border-radius: 10px 10px 0px 0px;
      }

      .mySwiper { 
        height: 20vh;
        width: 50vw;
        padding: 10px 0;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-top: none;
        border-radius: 0px 0px 10px 10px ;
      }

      .mySwiper .swiper-slide {
        width: 100%;
        height: 100%;
        opacity: 0.4;
      }

      .mySwiper .swiper-slide-thumb-active {
        opacity: 1;
      }

      .swiper-slide img {
        width: 100%;
        height: 100%;
       
      }

      @media (max-width: 930px) {
        .mySwiper2 {
            height: 45vh;
            width: 95vw;
          }
    
          .mySwiper { 
            height: 15vh;
            width: 95vw;
            
          }
      }
    